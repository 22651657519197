import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  path,
  siteUrl,
  siteTitle,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className='section section--gradient'>
      <Helmet
        title={`${title} | ${siteTitle}`}
        meta={[
          {
            name: "description",
            content: `${content
              .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
              .substr(0, 85)}`,
          },
          { property: "og:title", content: `${title} | ${siteTitle}` },
          { property: "og:url", content: `${siteUrl}${path}` },
          {
            property: "og:description",
            content: `${content
              .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
              .substr(0, 85)}`,
          },
        ]}
      />
      <div className='container content'>
        <div className='columns'>
          <div className='column is-10 is-offset-1'>
            <div className='section'>
              <h1 className='heading'>{title}</h1>
              <PageContent className='content' content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  pass: PropTypes.string.isRequired,
  siteUrl: PropTypes.string,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post, site: site } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        path={post.fields.slug}
        content={post.html}
        siteUrl={site.siteMetadata.siteUrl}
        siteTitle={site.siteMetadata.title}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
